import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { parseDate } from "../../utils/parseDate"
import { StrapiEvent } from "../../interfaces/Event"

const EventBlock = ({ event, url }: { event: StrapiEvent; url?: string }) => {
  return (
    <Link
      to={`${url ? url + "/" : ""}${event.slug}`}
      className="card cursor-pointer bg-base-200 bg-opacity-40"
    >
      {event.gallery && (
        <figure className="h-52 w-full object-cover px-5 pt-5">
          <GatsbyImage
            className="h-full w-full"
            image={event.gallery[0].localFile.childImageSharp?.thumb}
            alt={event.title}
          />
        </figure>
      )}
      <div className="card-body">
        <span className="card-title ">{event.title}</span>
        <span>{parseDate(event.from, event.to)}</span>
        <div className="flex flex-wrap gap-2">
          {event.tags &&
            event.tags.map((tag) => {
              return (
                <div key={tag.name} className={`badge badge-primary`}>
                  {tag.name}
                </div>
              )
            })}
        </div>
      </div>
    </Link>
  )
}

export default EventBlock
